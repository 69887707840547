<script lang="ts">
  import More from "$lib/MoreContent.svelte";
  import IshavskraftLogo from "$lib/assets/IshavskraftLogo.svelte";
  import { administrateCookieChoice } from "$lib/cookieConsent.svelte";
  import Button from "$lib/Button.svelte";

  const thisYear = new Date().getFullYear();
  let {
    backToTopHrefId,
  }: {
    backToTopHrefId?: string;
  } = $props();
</script>

<!-- bottom padding to make room for cookie banner at bottom -->
<footer
  id="mer"
  class="px-edge-x mt-20 pt-16 pb-32 [--bg:var(--primary)] [--fg:white] bg-$bg text-$fg z-8 relative"
>
  <More />

  <div class="landscape:flex flex-wrap justify-between">
    <div class="mt-20">
      <h2 class="sr-only">Ishavskraft AS</h2>

      <p class="text-body mb-3">
        <a href="/" aria-label="Ishavskraft" style="--logo-height:1.25em;--logo-color:var(--fg)">
          <IshavskraftLogo />
        </a>
      </p>

      <div class="children-my-1">
        <address class="not-italic">Ishavskraft AS, Markveien 6, 9510 Alta</address>
        <p>Org nr NO979 139 268</p>
        <p>
          &copy; {thisYear}
        </p>
      </div>
    </div>

    <div class="mt-20 text-end">
      <ul class="list-none p-0 border-r-solid border-r-1 border-$lysning">
        <li>
          <a class="button button-link min-w-0" href="/om/personvern">Personvern</a>
        </li>
        <li>
          <a class="button button-link min-w-0" href="/om/personvern/cookies">Cookies</a>
        </li>
        <li>
          <Button link class="min-w-0" onclick={() => administrateCookieChoice.set(true)}
            >Cookie-valg</Button
          >
        </li>
      </ul>

      {#if backToTopHrefId}
        <p>
          <a class="button min-h-[3rem]" href="#{backToTopHrefId || 'start'}">Til toppen av siden</a
          >
        </p>
      {/if}
    </div>
  </div>
</footer>
