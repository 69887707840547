<script lang="ts">
  
  interface Props {
    class?: string;
  }

  let { class: classes = "" }: Props = $props();
</script>

<svg
  aria-hidden="true"
  class={classes}
  width="34"
  height="34"
  viewBox="0 0 34 34"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M11.3337 12.7498H5.66699V28.3332H11.3337V12.7498Z" />
  <path d="M28.3337 18.4165H22.667V28.3332H28.3337V18.4165Z" />
  <path d="M19.8337 5.6665H14.167V28.3332H19.8337V5.6665Z" />
</svg>

<style lang="postcss">
  svg {
    height: var(--logo-height, 1em);
    width: auto;
  }

  path {
    fill: var(--color, currentColor);
  }
</style>
