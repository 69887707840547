<script lang="ts">
  
  interface Props {
    class?: string;
  }

  let { class: classes = "" }: Props = $props();
</script>

<svg
  aria-hidden="true"
  class={classes}
  width="24"
  height="30"
  viewBox="0 0 24 30"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.5013 29.1663C2.72214 29.1663 2.05536 28.8891 1.50097 28.3348C0.945635 27.7794 0.667969 27.1122 0.667969 26.333V9.33301C0.667969 8.55384 0.945635 7.88706 1.50097 7.33267C2.05536 6.77734 2.72214 6.49967 3.5013 6.49967H6.33464C6.33464 4.94134 6.8895 3.60731 7.99922 2.49759C9.10894 1.38787 10.443 0.833008 12.0013 0.833008C13.5596 0.833008 14.8937 1.38787 16.0034 2.49759C17.1131 3.60731 17.668 4.94134 17.668 6.49967H20.5013C21.2805 6.49967 21.9477 6.77734 22.5031 7.33267C23.0574 7.88706 23.3346 8.55384 23.3346 9.33301V26.333C23.3346 27.1122 23.0574 27.7794 22.5031 28.3348C21.9477 28.8891 21.2805 29.1663 20.5013 29.1663H3.5013ZM7.7513 13.583C8.15269 13.583 8.48939 13.447 8.76139 13.175C9.03244 12.904 9.16797 12.5677 9.16797 12.1663V9.33301H6.33464V12.1663C6.33464 12.5677 6.47064 12.904 6.74264 13.175C7.01369 13.447 7.34991 13.583 7.7513 13.583ZM9.16797 6.49967H14.8346C14.8346 5.72051 14.5574 5.05373 14.0031 4.49934C13.4477 3.94401 12.7805 3.66634 12.0013 3.66634C11.2221 3.66634 10.5554 3.94401 10.001 4.49934C9.44564 5.05373 9.16797 5.72051 9.16797 6.49967ZM16.2513 13.583C16.6527 13.583 16.9894 13.447 17.2614 13.175C17.5324 12.904 17.668 12.5677 17.668 12.1663V9.33301H14.8346V12.1663C14.8346 12.5677 14.9706 12.904 15.2426 13.175C15.5137 13.447 15.8499 13.583 16.2513 13.583Z"
    fill="white"
  />
</svg>

<style lang="postcss">
  svg {
    height: var(--logo-height, 1em);
    width: auto;
  }

  path {
    fill: var(--color, currentColor);
  }
</style>
