<script lang="ts">
  import IshavskraftLogo from "$lib/assets/IshavskraftLogo.svelte";
  import ContractIcon from "$lib/assets/ContractIcon.svelte";
  import ChartIcon from "$lib/assets/ChartIcon.svelte";
  import ShopIcon from "$lib/assets/ShopIcon.svelte";
  import CustomerServiceIcon from "$lib/assets/CustomerServiceIcon.svelte";
  import Button from "$lib/Button.svelte";

  interface Props {
    class?: string;
  }

  let { class: classes = "" }: Props = $props();
</script>

<div class="menu-content text-base {classes}">
  <div class="laptop:flex gap-10 desktop:gap-10">
    <div class="global landscape:flex gap-x-10 items-end children-my-0">
      <h2 class="mb-0" style="--logo-height:1.375em;--logo-color:var(--fg)">
        <a
          href="/"
          class="logo inline-flex items-center relative top-[3px]"
          aria-label="Ishavskraft"
        >
          <IshavskraftLogo />
        </a>
      </h2>

      <ul
        class="relative landscape:top-1.5 p-0 list-none flex gap-x-3 landscape:gap-x-8 text-sm laptop:text-base mb-0 mr-14"
      >
        <li>
          <a class="button button-link min-w-0 px-0 button-selected" href="/">Privat</a>
        </li>
        <li>
          <a
            class="underline font-400 button button-link min-w-0 px-0"
            href="//bedriftskunde.ishavskraft.no">Bedrift</a
          >
        </li>
        <li>
          <a
            class="underline font-400 button button-link min-w-0 px-0"
            href="//bedriftskunde.ishavskraft.no/konsesjonskraft">Konsesjonskraft</a
          >
        </li>
      </ul>
    </div>
  </div>

  <div class="gap-x-8 laptop:grid laptop:grid-cols-2 mb-4 laptop:mt-6">
    <nav
      aria-label="Hovedsider"
      class="gap-x-8 landscape:grid landscape:grid-cols-2 children-mt-10"
    >
      <ul class="text-lead p-0 list-none grid gap-3 laptop:gap-2 relative laptop:top-[-.2rem]">
        <li>
          <a
            class="inline-grid grid-cols-[3rem_1fr] place-items-center font-600 plausible-event-name=Strømavtaler+meny"
            href="/privat/avtaler"
            style="--logo-height:1.5em"
          >
            <ContractIcon class="mr-2" /> <span>Strømavtaler</span>
          </a>
        </li>
        <li>
          <a
            class="inline-grid grid-cols-[3rem_1fr] place-items-center font-600"
            href="/privat/kundeservice"
            style="--logo-height:1.125em"
            ><CustomerServiceIcon class="mr-2" /> <span>Kundeservice</span></a
          >
        </li>
        <li>
          <a
            class="inline-grid grid-cols-[3rem_1fr] place-items-center font-600"
            href="/privat/dagens-strompris"
            style="--logo-height:1.4em"><ChartIcon class="mr-2" /> <span>Spotpris</span></a
          >
        </li>
        <li>
          <a
            class="inline-grid grid-cols-[3rem_1fr] place-items-center font-600"
            href="/nettbutikk"
            style="--logo-height:1.35em"><ShopIcon class="mr-2" /> <span>Nettbutikk</span></a
          >
        </li>
      </ul>

      <section class="text-body children-my-0">
        <h3>Snarveier</h3>

        <ul class="p-0 list-none border-l-solid border-l-1 border-$lysning">
          <li>
            <Button link textStart href="//minside.ishavskraft.no" rel="nofollow">Min side</Button>
          </li>
          <li>
            <Button
              textStart
              link
              class="plausible-event-name=Bli+kunde+meny"
              href="/privat/avtaler">Bli kunde</Button
            >
          </li>
          <li>
            <Button textStart link href="/om/aktuelt">Artikler</Button>
          </li>
          <li>
            <Button textStart link href="/om/aktuelt/sparetips">Sparetips</Button>
          </li>
          <li>
            <Button textStart link href="/privat/skjema">Skjemaer</Button>
          </li>
          <li>
            <Button textStart link href="/alle-avtaler">Prisliste</Button>
          </li>
          <li>
            <Button textStart link href="/privat/smartlading">Smartlading</Button>
          </li>
          <li>
            <Button textStart link href="/privat/hva-koster">Strømkalkulator</Button>
          </li>
        </ul>
      </section>
    </nav>

    <div class="gap-x-8 landscape:grid landscape:grid-cols-2 children-mt-10">
      <section class="text-body children-my-0">
        <h3>Annet</h3>

        <ul class="p-0 list-none border-l-solid border-l-1 border-$lysning">
          <li>
            <Button textStart link href="//bedriftskunde.ishavskraft.no/om-oss/">Om oss</Button>
          </li>
          <li>
            <Button textStart link href="//bedriftskunde.ishavskraft.no/sponsing/">Sponsing</Button>
          </li>
          <li>
            <Button textStart link href="//bedriftskunde.ishavskraft.no/ledige-stillinger/"
              >Ledige stillinger</Button
            >
          </li>
          <li>
            <Button textStart link href="//bedriftskunde.ishavskraft.no/media-og-presse/"
              >Media og presse</Button
            >
          </li>
          <li>
            <Button textStart link href="//bedrift.ishavskraft.no/" rel="nofollow"
              >Online bedrift</Button
            >
          </li>
        </ul>
      </section>

      <section class="desktop:text-body children-my-0">
        <h3>Kontakt oss</h3>

        <ul class="p-0 list-none">
          <li>
            <p class="font-normal min-h-[3rem] m-0 flex items-center">Ring oss på</p>
            <a class="flex flex-col items-start" href="tel:+4777612000">
              <p class="m-0 text-lead">776 12 000</p>
            </a>
            <p class="mt-0">Man-fre 09:00-15:30</p>
          </li>

          <li>
            <a
              class="no-underline flex-col items-start children-my-1 flex"
              href="mailto:kunde@ishavskraft.no"
              ><p class="font-normal">E-post</p>
              <p class="underline hyphens-none">kunde@ishavskraft.no</p></a
            >
          </li>
        </ul>
      </section>
    </div>
  </div>
</div>

<style>
  h3 {
    font-size: var(--text-h2);
    font-weight: bold;
    padding-block: var(--s-2);
  }

  h3 ~ ul,
  h3 ~ ul > li {
    margin-block: calc(0.5 * var(--text-base)) 0;
  }

  :where(:global(a)) {
    min-height: calc(3 * var(--text-base));
  }
</style>
