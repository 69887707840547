<script lang="ts">
  import { beforeNavigate } from "$app/navigation";
  import IshavskraftLogo from "$lib/assets/IshavskraftLogo.svelte";
  import Button from "$lib/Button.svelte";

  const toggleMenu = (e: Event) => {
    // open dialog if supported
    if (typeof HTMLDialogElement === "function") {
      e.preventDefault();

      if (!menuInit) {
        menuInit = true;

        // clone #mer content and prepend to dialog
        const moreContent = document.querySelector("#mer .menu-content");

        if (moreContent) {
          const clone = moreContent.cloneNode(true);
          dialogMenu.appendChild(clone);
          // change nav aria-label for unique landmark
          dialogMenu.querySelector("nav")?.setAttribute("aria-label", "Hovedsider via Meny");
        }
      }

      dialogMenu.showModal();
    }
  };

  const toggleMyPage = (e: Event) => {
    // open dialog if supported
    if (typeof HTMLDialogElement === "function") {
      e.preventDefault();
      dialogMyPage.showModal();
    }
  };

  let menuInit = false;
  interface Props {
    class?: string;
  }

  let { class: classes = "" }: Props = $props();
  let dialogMenu: HTMLDialogElement = $state();
  let dialogMyPage: HTMLDialogElement = $state();

  beforeNavigate(() => {
    dialogMenu.close();
    dialogMyPage.close();
  });
</script>

<header class="text-base {classes}">
  <a href="#hovedinnhold" class="button button-primary not-focus:sr-only absolute z-8 left-.5edge-x"
    >Hopp til hovedinnholdet</a
  >

  <div class="grid grid-cols-[1fr_auto] tablet:grid-cols-[auto_1fr] tablet:gap-6 items-start">
    <p class="m-0 relative top-1 tablet:top-.5 h-48px w-143px">
      <a
        href="/"
        class="logo fixed z-7 left-edge-x laptop:left-[calc(var(--space-edge-x)-.75rem)] leading-[3] outline-none text-$focus focus:shadow-[0_3px]"
        aria-label="Ishavskraft"
      >
        <IshavskraftLogo />
      </a>
    </p>

    <nav aria-label="Global" class="ishavskraft-global">
      <ul
        class="m-0 p-0 list-none grid grid-cols-[repeat(3,auto)_1fr] gap-$_s items-start color-$primary"
      >
        <li>
          <a
            class="hidden tablet:inline-block button button-link min-w-0 px-0 button-selected"
            href="/">Privat</a
          >
        </li>
        <li>
          <a
            class="hidden tablet:inline-block button button-link min-w-0 px-0 hover:underline focus:underline"
            href="//bedriftskunde.ishavskraft.no">Bedrift</a
          >
        </li>
        <li>
          <a
            class="hidden button button-link min-w-0 px-0 tablet:inline-block hover:underline focus:underline"
            href="//bedriftskunde.ishavskraft.no/konsesjonskraft">Konsesjonskraft</a
          >
        </li>
        <li
          class="flex items-start gap-$_s fixed top-2 right-edge-x laptop:right-[calc(var(--space-edge-x)-.75rem)] z-10 fixed-container"
        >
          <p class="m-0 flex justify-end items-start">
            <a
              class="button min-w-0 px-3 grid grid-cols-[minmax(0,1fr)_auto] justify-center gap-[.3ch] landscape:pr-2 max-landscape:w-[3rem]"
              onclick={toggleMyPage}
              href="//minside.ishavskraft.no"
              rel="nofollow"
            >
              <span>
                <span class="max-landscape:sr-only">Min side</span>
              </span>
              <span class="block w-1.5rem relative h-1.5rem">
                <span class="absolute top--.1rem left--1px block i-mdi:user w-1.5rem h-1.5rem"
                ></span>
              </span>
            </a>
          </p>
          <p class="m-0">
            <a
              onclick={toggleMenu}
              class="min-w-0 button px-3 flex items-center gap-[.5ch]"
              href="#mer"
            >
              <span>Meny</span>
              <span class="hamburger-icon"></span>
            </a>
          </p>
        </li>
      </ul>
    </nav>
  </div>
</header>

<!-- svelte-ignore a11y_click_events_have_key_events -->
<!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
<dialog
  bind:this={dialogMyPage}
  class="mt-[4.275rem] mr-edge-x py-2 landscape:pb-6"
  onclick={(event) => {
    const rect = dialogMyPage.getBoundingClientRect();
    const isInDialog =
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width;
    if (!isInDialog) dialogMyPage.close();
  }}
>
  <p class="text-end my-0">
    <button
      onclick={() => dialogMyPage.close()}
      class="close-button button button-link relative right-[calc(-1*var(--p-button,0))] min-w-[3rem] px-0"
    >
      <span class="sr-only">Lukk</span> <span class="close-icon"></span>
    </button>
  </p>
  <h2 class="sr-only">Velg innlogging</h2>
  <ul class="list-none p-0 grid gap-x-4 gap-y-2 my-2">
    <li>
      <Button
        class="bg-$glod border-$aurora plausible-event-name=Min+side"
        href="//minside.ishavskraft.no"
        arrow={true}
        rel="nofollow">Min side</Button
      >
    </li>
    <li>
      <Button
        href="//bedrift.ishavskraft.no/"
        class="align-start plausible-event-name=Online+bedrift"
        rel="nofollow">Online bedrift</Button
      >
    </li>
  </ul>
</dialog>

<!-- svelte-ignore a11y_click_events_have_key_events -->
<!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
<dialog
  bind:this={dialogMenu}
  class="menu pb-16 text-body w-full max-w-none px-edge-x m-0 max-h-[100dvh] rounded-0"
  onclick={(event) => {
    const rect = dialogMenu.getBoundingClientRect();
    const isInDialog =
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width;
    if (!isInDialog) dialogMenu.close();
  }}
>
  <p class="absolute top-[1.25em] right-edge-x my-0">
    <button
      onclick={() => dialogMenu.close()}
      class="close-button button button-link relative right-[calc(-1*var(--p-button,0))] min-w-[3rem]"
    >
      <span class="sr-only">Lukk</span> <span class="close-icon"></span>
    </button>
  </p>
</dialog>

<style lang="postcss">
  /* variable to use for sticky top elements, takes Header fixed height into account */
  :global(:root) {
    --sticky-top: 5rem;
  }

  :where(:global(header)) {
    --_s: var(--s-2);
    --logo-svg-height: 1.75em;

    padding: var(--s-2) var(--space-edge-x);

    :where(:global(a, button)) {
      min-width: 3rem;
      height: 3rem;
    }

    :where(:global(a:not(.button))) {
      display: inline-block;
    }
  }

  @media (--tablet) {
    header {
      --_s: var(--s-3);
    }
  }

  @media (--laptop) {
    header {
      --_s: var(--s-4);
    }
  }

  .logo {
    &::after {
      content: "";
      background: color-mix(in oklab, var(--bg-root, var(--bg)) 25%, transparent);
      border-radius: var(--rounded-pill);
      filter: blur(0.5rem);
      position: absolute;
      inset: 0 calc(-1 * min(var(--space-edge-x), var(--p-button)));
      z-index: -1;
    }

    & :global(svg) {
      color: var(--primary);
      filter: drop-shadow(
        0 0 3px color-mix(in oklab, transparent, var(--bg-root, var(--bg)) 87.5%)
      );
      height: var(--logo-svg-height);
      width: auto;
    }
  }

  .ishavskraft-global,
  .fixed-container {
    padding-block-start: calc(0.125 * var(--logo-svg-height) + 1px);
  }

  .hamburger-icon {
    --_line-height: 2px;
    background: no-repeat center / 100% var(--_line-height)
      linear-gradient(currentColor, currentColor);
    display: inline-block;
    height: 14px; /* calculate from --text-base and adjust px size for --_line-height */
    width: 1.25em;
    border-top: var(--_line-height) solid;
    border-bottom: var(--_line-height) solid;
  }

  dialog {
    filter: drop-shadow(0 1.5rem 2rem rgb(0 0 0 / 0.5));
    &::backdrop {
      background: none;
    }
  }

  .close-button:focus:not(:hover, :active, :focus-visible) {
    border-color: transparent;
    box-shadow: none;
  }
</style>
