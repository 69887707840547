<script lang="ts">
  
  interface Props {
    class?: string;
  }

  let { class: classes = "" }: Props = $props();
</script>

<svg
  aria-hidden="true"
  class={classes}
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 400 567.4"
>
  <defs>
    <style>
      .cls-1 {
        stroke-width: 0px;
      }
    </style>
  </defs>
  <path
    class="cls-1"
    d="M366,212.8h-108.6V34c0-33.7-43.7-46.8-62.3-18.7L5.7,301.9c-14.9,22.6,1.3,52.7,28.3,52.7h108.6v178.8c0,21,17,34,34.3,34s21-4.7,28-15.3l189.4-286.6c14.9-22.6-1.3-52.7-28.3-52.7M379.1,255.4l-189.4,286.6c-3.9,5.9-9,7.1-12.7,7.1-6.2,0-16-4.3-16-15.7v-178.8h62.5c18.8,0,34-15.2,34-34v-89.5h108.6c8.5,0,12.5,5.8,13.8,8.2,1.3,2.5,3.9,9-.7,16.1"
  />
</svg>

<style lang="postcss">
  svg {
    height: var(--logo-height, 1em);
    width: auto;
  }

  path {
    fill: var(--color, currentColor);
  }
</style>
